// JsFromRoutes CacheKey b75257eddec964ad261ac642ae1ace39
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  confirmStateChange: definePathHelper('post', '/template_packs/clips/confirm_state_change'),
  archive: definePathHelper('post', '/template_packs/clips/archive'),
  restore: definePathHelper('post', '/template_packs/clips/restore'),
  duplicate: definePathHelper('post', '/template_packs/clips/duplicate'),
  list: definePathHelper('get', '/template_packs/clips'),
  get: definePathHelper('get', '/template_packs/clips/:id'),
}
