// JsFromRoutes CacheKey ed1a74faf712517d916b1b4feefaf1de
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  list: definePathHelper('get', '/admin/settings'),
  create: definePathHelper('post', '/admin/settings'),
  new: definePathHelper('get', '/admin/settings/new'),
  edit: definePathHelper('get', '/admin/settings/:id/edit'),
  update: definePathHelper('patch', '/admin/settings/:id'),
  destroy: definePathHelper('delete', '/admin/settings/:id'),
}
