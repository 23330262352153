// JsFromRoutes CacheKey 5743b821e055218e77d003d99fbba687
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  new: definePathHelper('get', '/admin/organisations/:organisation_id/customisation/new'),
  edit: definePathHelper('get', '/admin/organisations/:organisation_id/customisation/edit'),
  update: definePathHelper('patch', '/admin/organisations/:organisation_id/customisation'),
  create: definePathHelper('post', '/admin/organisations/:organisation_id/customisation'),
}
