// JsFromRoutes CacheKey ba57542ec2d54f60320735e64999d6ef
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  pendingActions: definePathHelper('post', '/deals/:deal_id/public/pending_actions'),
  exportXlsx: definePathHelper('post', '/deals/:deal_id/public/export_xlsx'),
  exportData: definePathHelper('post', '/deals/:deal_id/public/export_data'),
  export: definePathHelper('post', '/deals/:deal_id/public/export'),
  list: definePathHelper('get', '/deals/:deal_id/public'),
  create: definePathHelper('post', '/deals/:deal_id/public'),
  update: definePathHelper('patch', '/deals/:deal_id/public/:id'),
}
