// JsFromRoutes CacheKey e0de31151865a5a34bf7dad2870ea506
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  create: definePathHelper('post', '/template_packs'),
  list: definePathHelper('get', '/template_packs'),
  destroy: definePathHelper('delete', '/template_packs'),
  update: definePathHelper('patch', '/template_packs/:id'),
  conflicts: definePathHelper('get', '/template_packs/:id/conflicts'),
  revert: definePathHelper('post', '/template_packs/:id/revert'),
  launch: definePathHelper('get', '/template_packs/:id/launch'),
  triggerLaunch: definePathHelper('post', '/template_packs/:id/trigger_launch'),
  unblacklistUuid: definePathHelper('post', '/template_packs/:id/unblacklist_uuid'),
  templatePackQuestionnaireVersions: definePathHelper('get', '/template_packs/:id/template_pack_questionnaire_versions'),
  attributesByTemplates: definePathHelper('get', '/template_packs/attributes_by_templates'),
  importFolder: definePathHelper('get', '/template_packs/import_folder'),
  new: definePathHelper('get', '/template_packs/new'),
  edit: definePathHelper('get', '/template_packs/:id/edit'),
}
