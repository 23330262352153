// JsFromRoutes CacheKey 553aa8460112c47c4576501b7e46ba79
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  new: definePathHelper('get', '/users/confirmation/new'),
  get: definePathHelper('get', '/users/confirmation'),
  create: definePathHelper('post', '/users/confirmation'),
  update: definePathHelper('patch', '/user/confirmation'),
}
