// JsFromRoutes CacheKey 7252e380fc72e343f6c527f2d5287bbb
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from '@js-from-routes/axios'

export default {
  new: definePathHelper('get', '/users/sign_in'),
  create: definePathHelper('post', '/users/sign_in'),
  destroy: definePathHelper('delete', '/users/sign_out'),
  handleOtpStep: definePathHelper('post', '/users/auth/handle_otp_step'),
  handleSplashscreenStep: definePathHelper('post', '/users/auth/handle_splashscreen_step'),
  validateSso: definePathHelper('post', '/users/auth/validate_sso'),
}
